import styles from './index.module.scss'

export default (props) => {
    const {avatar, children} = props

    return (
        <div className={styles.container}>
            <div className={styles.avatar}>
                <img width="100%" height="160px" src={avatar}/>
            </div>

            <div className={styles.body}>
                {children}
            </div>
        </div>
    );
}
