import "react-tooltip/dist/react-tooltip.css"
import { Tooltip as ReactTooltip } from "react-tooltip";

export default (props) => {
    const {language, setLanguage} = props;

    const switchLanguage = () => {
        const newLanguage = (language === "en") ? "br" : "en";

        setLanguage(newLanguage);
    }

    const tooltipContent = (language === "en") ?
        "Click here to change language." :
        "Clique aqui para mudar o idioma.";

    const style_language_selected = {
        top: 6,
        left: 4,
        zIndex: 2
    };

    return (
        <div>
            <div
                id="switch-language"
                className="absolute top-5 right-5 cursor-pointer w-10 h-10"
                onClick={() => switchLanguage()}
            >
                <img
                    className={`absolute`}
                    src="/assets/img/flag/Brazil.svg"
                    width={32}
                    height={24}
                    style={(language === "br") ? style_language_selected : {}}
                />

                <img
                    className="absolute"
                    src="/assets/img/flag/United_States.svg"
                    width={32}
                    height={24}
                    style={(language === "en") ? style_language_selected : {}}
                />
            </div>

            <ReactTooltip
                anchorId="switch-language"
                place="bottom"
                content={tooltipContent}
            />
        </div>
    );
}
