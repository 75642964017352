import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { easeLinear } from "d3-ease";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../AnimatedProgressProvider";
import Card from "../Card";

export default (props) => {
    const {skills} = props

    const renderSkiils = () => {
        return skills.map(skill => {
            return (
                <AnimatedProgressProvider
                    key={skill}
                    valueEnd={skill.percentage}
                    duration={1.1}
                    easingFunction={easeLinear}
                >
                    {value => {
                        const percentage = Math.round(value);

                        return (
                            <div
                                className="flex flex-col text-center gap-3"
                                style={{
                                    width: 130
                                }}
                            >
                                <CircularProgressbar
                                    value={percentage}
                                    text={`${percentage}`}
                                    styles={buildStyles({
                                        textColor: 'var(--danger)',
                                        pathColor: 'var(--danger)'
                                    })}
                                />
                                <h3 className="text-color-white">{skill.name}</h3>
                            </div>
                        );
                    }}
                </AnimatedProgressProvider>
            )
        })
    }

    return (
        <Card title="Skills">
            <div className="flex flex-wrap justify-center gap-5">
                {renderSkiils()}
            </div>
        </Card>
    );
}
