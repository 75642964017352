import Card from "../Card";

export default (props) => {
    const {achievements} = props

    const renderAchievements = () => {
        return achievements.map(achievement => {
            return (
                <div className="card border-bottom-danger">
                    <img src={achievement.image}/>

                    {achievement.description && <div>{achievement.description}</div>}
                </div>
            )
        })
    }

    return (
        <Card title="Achievements">
            <div className="flex flex-wrap justify-center gap-5">
                {renderAchievements()}
            </div>
        </Card>
    );
}
