export default (props) => {
    const {title, children} = props

    return (
        <div className="flex flex-col gap-3">
            <h2 className="text-color-white">{title}</h2>

            <div className="flex flex-col gap-3">
                {children}
            </div>
        </div>
    );
}
