import Card from "../Card"

export default (props) => {
    const {experiences} = props

    const renderExperiences = () => {
        return experiences.map(experience => {
            return (
                <div key={experience} className="card box-shadow-danger">
                    <div className="flex items-center gap-3">
                        <img src={experience.companyImage} width={64} />

                        <div className="flex flex-col gap-1">
                            <h2>{experience.office}</h2>

                            <div>
                                <h4 className="text-color-gray">{experience.company}</h4>
                                <h6 className="text-color-gray">{experience.startAt} - {experience.endAt}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <Card title="Experiences">
            {renderExperiences()}
        </Card>
    );
}
