import { useEffect, useState } from "react";
import StickyBox from "react-sticky-box";
import CardAchievements from "./components/CardAchievements";
import CardExperiences from "./components/CardExperiences";
import CardSkills from "./components/CardSkills";
import CardWithAvatar from "./components/CardWithAvatar";
import Loading from "./components/Loading";
import SwitchLanguage from "./components/SwitchLanguage";

export default () => {
    const [language, setLanguage] = useState("en");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 700);
    }, [])

    const skills = [
        {
            name: "Python",
            percentage: 100
        },
        {
            name: "A7:XSS",
            percentage: 100
        },
        {
            name: "A1:Injection",
            percentage: 100
        },
        {
            name: "A5:Broken Access Control",
            percentage: 100
        },
    ];

    const experiences = [
        {
            office: "DevSec",
            company: "Estuda.com",
            companyImage: "https://cdng.estuda.com/design/10/icone.png",
            startAt: "February 2021",
            endAt: "To date"
        }
    ];

    const achievements = [
        {
            "image": "/assets/img/achievements/leaderboard.png",
            "description": "On 10/12/2021 I managed to get among the 10 best hackers in the world in the ranking of HackerOne between October and December 2021."
        },
        {
            "image": "/assets/img/achievements/1652921907915.jpg",
            "description": ""
        },
        {
            "image": "/assets/img/achievements/1652921985649.jpg",
            "description": ""
        },
        {
            "image": "/assets/img/achievements/1652987269885.jpg",
            "description": ""
        },
        {
            "image": "/assets/img/achievements/1664646539162.jpg",
            "description": ""
        },
        {
            "image": "/assets/img/achievements/1664646612642.jpg",
            "description": ""
        },
        {
            "image": "/assets/img/achievements/1673108235591.jpg",
            "description": ""
        },
        {
            "image": "/assets/img/achievements/1673108347008.jpg",
            "description": ""
        }
    ];

    const aboutArthur = () => {
        if (language === "br") {
            return (
                <>
                    <p>Meu nome é Arthur Aires, sou autodidata e desde a adolescência, hoje tenho 22 anos, passava horas no computador testando websites e aplicações Android.</p>
                    <p>Passei em Medicina em Ampla Concorrência na Universidade Federal do Amapá em 2ª Lugar e 1ª Lugar na Universidade Federal do Pará.</p>
                    <p>Tenho habilidades de reconhecimento de escopo e pentest em aplicações server side e client side.</p>
                    <p>Encontrei mais de 200 vulnerabilidades na HackerOne e dentre essas várias vulnerabilidades de alto impacto como RCE, SSRF, SQL Injection, LFI e Vazamento de Informações Pessoais em diversos programas da plataforma.</p>
                    <p>Fui convidado três vezes pela HackerOne (H1-512, H1-702 e H1-3493) para o LHE com tudo pago para hackear programas pessoalmente.</p>
                    <p>No ano de 2021 me inscrevi na HackerOne e somente com programas de Bug Bounty fiquei entre os 5 melhores hackers do Brasil e no ano de 2022 continuo entre os melhores somente com Bug Bounty.</p>
                </>
            )
        }

        return (
            <>
                <p>My name is Arthur Aires, I am self-taught and since I was a teenager, I am now 22 years old, I spent hours on the computer testing websites and Android applications.</p>
                <p>I passed Medicine in Wide Competition at the Federal University of Amapá in 2nd place and 1st place at the Federal University of Pará.</p>
                <p>I have scope recognition and pentesting skills in server side and client side applications.</p>
                <p>I found more than 200 vulnerabilities on HackerOne and among these several high impact vulnerabilities such as RCE, SSTI, SSRF, SQL Injection, LFI and Personal Information Leakage in various programs on the platform.</p>
                <p>I have been invited three times by HackerOne (H1-512, H1-702 and H1-3493) to the all-expenses paid LHE to hack programs in person.</p>
                <p>In 2021 I signed up for HackerOne and only with Bug Bounty programs I was among the 5 best hackers in Brazil and in 2022 I am still among the best with only Bug Bounty.</p>
            </>
        )
    };

    return (
        <>
            <Loading loading={isLoading} />

            <div className="container mx-auto mx-auto pt-16 pb-5 px-30 max-md:px-5 flex flex-col gap-10">
                <div id="main-container">
                    <StickyBox className="flex flex-col gap-5 flex-1 stickyBox">
                        <CardWithAvatar avatar="/assets/img/10226317.jpg">
                            <div className="flex flex-col gap-1">
                                <h1><span className="text-color-danger">A</span>rthur <span className="text-color-danger">A</span>ires</h1>

                                {aboutArthur()}
                            </div>

                            <div className="flex justify-end gap-2">
                                <a href="https://www.linkedin.com/in/arthur-a-93388211b/"><img src="/assets/img/linkedin.png" alt="Linkedin" width="24"/></a>
                                <a href="https://github.com/arthur4ires"><img src="/assets/img/github.png" alt="Github" width="24"/></a>
                                <a href="https://hackerone.com/arthuraires"><img src="/assets/img/hackerone.png" alt="Github" width="24"/></a>
                                <a href="https://twitter.com/arthurair_es"><img src="/assets/img/twitter.png" alt="Github" width="24"/></a>
                            </div>

                            <SwitchLanguage language={language} setLanguage={setLanguage} />
                        </CardWithAvatar>

                        <CardSkills skills={skills} />

                        <CardExperiences experiences={experiences} />
                    </StickyBox>

                    <div className="flex flex-col gap-5 flex-1">
                        <CardAchievements achievements={achievements} />
                    </div>
                </div>

                <div className="text-center text-color-white">
                    Todos os direitos reservados à <span className="font-bold text-color-danger">Arthur Aires</span>.
                </div>
            </div>
        </>
    );
}
